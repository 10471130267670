var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentRole === 4 || _vm.currentRole === 5)?_c('div',{attrs:{"id":"center-management"}},[_c('v-row',{staticClass:"pb-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"pa-10 pt-5"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h2',[_vm._v("QC salary")])]),_c('v-row',{staticClass:"mt-5 mb-5 pt-2",staticStyle:{"background-color":"rgba(196, 196, 196, 0.25)"}},[_c('v-col',{attrs:{"md":"2"}},[_c('v-text-field',{staticStyle:{"background":"white"},attrs:{"type":"date","label":"From date","outlined":"","dense":"","hide-details":""},on:{"input":_vm.search},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-text-field',{staticStyle:{"background":"white"},attrs:{"type":"date","label":"To date","outlined":"","dense":"","hide-details":""},on:{"input":_vm.search},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1),_c('v-col',{attrs:{"md":"2"}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.qcSalary,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","item-key":"stt","id":_vm.tables,"fixed-header":"","height":"100%","hide-default-header":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,staticClass:"text-start font-weight-bold",style:('width:' + header.width + '; min-width:' + header.width + ';'),attrs:{"role":"columnheader","scope":"col","aria-label":"No"}},[(header.value == 'rate' || header.value == 'amount')?_c('span',{staticStyle:{"float":"right"}},[_vm._v(_vm._s(header.text))]):_c('span',[_vm._v(_vm._s(header.text))])])}),0)]),_c('tr',{staticClass:"fixed-row-filter"},_vm._l((props.headers),function(header){return _c('th',{key:header.text},[(_vm.filters.hasOwnProperty(header.value))?_c('div',[(_vm.filters.hasOwnProperty(header.value))?_c('Autocomplete',{attrs:{"styleBorder":'border-bottom',"keyFilter":header.value,"selectedInit":_vm.filters[header.value],"listItem":_vm.groupColumnValueList(header.value)},on:{"setFilter":_vm.setFilter}}):_vm._e()],1):_vm._e()])}),0)]}},{key:"item.no",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.rate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.rate))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.amount))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"18","color":"primary"},on:{"click":function($event){return _vm.viewDetail(item.teacherID, item.locationID)}}},[_vm._v(" mdi-eye ")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)])],1),_c('v-footer',{staticClass:"px-10 pb-2",staticStyle:{"display":"flex","justify-content":"space-between"},style:(_vm.$store.state.isActiveSidebar ? 'width: calc(100% - 100px)' : 'width: calc(100% - 258px)'),attrs:{"color":"#fff","padless":""}},[_c('div',[_c('v-select',{staticStyle:{"height":"32px"},attrs:{"outlined":"","hide-details":"","dense":"","value":_vm.itemsPerPage,"items":[20, 50, 100, 200, 500, 1000]},on:{"change":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1),_c('div',[(_vm.qcSalary.length != 1)?_c('h3',[_vm._v("Total: "+_vm._s(_vm.qcSalary.length)+" records")]):_c('h3',[_vm._v("Total: "+_vm._s(_vm.qcSalary.length)+" record")])]),_c('div',[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":7,"elevation":"0"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","width":"350"},model:{value:(_vm.errorDialog),callback:function ($$v) {_vm.errorDialog=$$v},expression:"errorDialog"}},[_c('v-card',{staticClass:"mx-auto pa-10"},[_c('div',{staticClass:"text-center"},[_c('h1',[_vm._v("Error!")]),_c('p',[_vm._v(_vm._s(_vm.message.messages.UNKNOWN_ERROR))])]),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"error","elevation":"0","outlined":""},on:{"click":function($event){return _vm.back()}}},[_vm._v(" Back ")])],1)])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }